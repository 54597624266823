import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import 'element-plus/theme-chalk/display.css';
import './browserPatch.js';
import scui from './scui';
import i18n from './locales';
import router from './router';
import store from './store';
import '@/assets/styles/iconfont.css';
import _ from 'lodash';
import { createApp } from 'vue';
import App from './App.vue';
import 'default-passive-events'; //页面滑动卡顿解决依赖
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(i18n);
app.use(scui);
app.use(pinia)


app.config.globalProperties.$piniaStore = pinia;


//挂载app
app.mount('#app');
