import axios from 'axios';
import { ElNotification, ElMessageBox } from 'element-plus';
// import {ElMessageBox, ElNotification} from 'element-plus';
import fileDownload from 'js-file-download';

import sysConfig from '@/config';
import tool from '@/utils/tool';
import router from '@/router';

axios.defaults.baseURL = '';

axios.defaults.timeout = sysConfig.TIMEOUT;

// HTTP request 拦截器
axios.interceptors.request.use(
	(config) => {
		let token = tool.data.get('TOKEN');
		if (token) {
			config.headers[sysConfig.TOKEN_NAME] = sysConfig.TOKEN_PREFIX + token;
		}
		if (!sysConfig.REQUEST_CACHE && config.method == 'get') {
			config.params = config.params || {};
			config.params['_'] = new Date().getTime();
		}

		Object.assign(config.headers, sysConfig.HEADERS);
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

// HTTP response 拦截器
axios.interceptors.response.use(
	(response) => {

		return response;
	},
	(error) => {
		if (error.response) {
			if (error.response.status == 404) {
				ElNotification.error({
					title: '请求错误',
					message: 'Status:404，正在请求不存在的服务器记录！',
				});
			} else if (error.response.status == 500) {
				ElNotification.error({
					title: '请求错误',
					message:
						error.response.data.message || 'Status:500，服务器发生错误！',
				});
			} else if (error.response.status == 401) {
				// ElMessageBox.confirm('当前用户已被登出或无权限访问当前资源，请尝试重新登录后再操作。', '无权限访问', {
				// 	type: 'error',
				// 	closeOnClickModal: false,
				// 	center: true,
				// 	confirmButtonText: '重新登录'
				// }).then(() => {
				/*ElNotification.error({
						title: '请求错误',
						message: error.response.data.message || "账户已退出，请重新登录后操作！"
					});*/
				router.replace({ path: '/login' });
				// }).catch(() => {})
			} else if (error.response.status == 406) {
				// 弹出提示
				ElMessageBox.confirm('此功能为VIP用户专属', '提示', {
					confirmButtonText: '购买VIP',
					type: 'info',
					showClose:false,
					closeOnClickModal:false,
					showCancelButton:false
				})
					.then(() => {
						router.push({ path: '/service/vip' });
					})
					.catch(() => {
						// console.log('no');
					});
			} else {
				ElNotification.error({
					title: '请求错误',
					message:
						error.response.data.message ||
						`Status:${error.response.status}，未知错误！`,
				});
			}
		} else {
			ElNotification.error({
				title: '请求错误',
				message: '请求服务器无响应！',
			});
		}

		return Promise.reject(error.response);
	}
);

const http = {
	/** get 请求
	 * @param  {string} url
	 * @param  {object} params
	 * @param  {object} config
	 */
	get: function (url, params = {}, config = {}) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'get',
				url: url,
				params: params,
				...config,
			})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	/** post 请求
	 * @param  {string} url
	 * @param  {object} data
	 * @param  {object} config
	 */
	post: function (url, data = {}, config = {}) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'post',
				url: url,
				data: data,
				...config,
			})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	/** put 请求
	 * @param  {string} url
	 * @param  {object} data
	 * @param  {object} config
	 */
	put: function (url, data = {}, config = {}) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'put',
				url: url,
				data: data,
				...config,
			})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	/** patch 请求
	 * @param  {接口地址} url
	 * @param  {请求参数} data
	 * @param  {参数} config
	 */
	patch: function (url, data = {}, config = {}) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'patch',
				url: url,
				data: data,
				...config,
			})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	/** delete 请求
	 * @param  {string} url
	 * @param  {请求参数} data
	 * @param  {参数} config
	 */
	delete: function (url, data = {}, config = {}) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'delete',
				url: url,
				data: data,
				...config,
			})
				.then((response) => {
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},

	/** jsonp 请求
	 * @param  {接口地址} url
	 * @param  {JSONP回调函数名称} name
	 */
	jsonp: function (url, name = 'jsonp') {
		return new Promise((resolve) => {
			const script = document.createElement('script');
			script.id = `jsonp${Math.ceil(Math.random() * 1000000)}`;
			script.type = 'text/javascript';
			script.src = url;
			window[name] = (response) => {
				resolve(response);
				document.getElementsByTagName('head')[0].removeChild(script);
				try {
					delete window[name];
				} catch (e) {
					window[name] = undefined;
				}
			};
			document.getElementsByTagName('head')[0].appendChild(script);
		});
	},

	download: async function (url, params, filename) {
		await new Promise((resolve, reject) => {
			axios({
				method: 'get',
				url: url,
				params: params,
				responseType: 'blob',
				timeout: 60000
			})
				.then((response) => {
					console.log(response.headers['content-disposition']);
					var fileName = filename;
					if (response.headers['content-disposition'] != undefined) {
						fileName = response.headers['content-disposition']
							.split(';')[1]
							.split('filename=')[1];
					}
					resolve(response);
					fileDownload(response.data, fileName);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	downloadPost: async function (url, data, filename) {
		await new Promise((resolve, reject) => {
			axios({
				method: 'post',
				url: url,
				data: data,
				responseType: 'blob',
				timeout: 60000
			})
				.then((response) => {
					console.log(response.headers['content-disposition']);
					var fileName = filename;
					if (response.headers['content-disposition'] != undefined) {
						fileName = response.headers['content-disposition']
							.split(';')[1]
							.split('filename=')[1];
					}

					fileDownload(response.data, fileName);
					resolve(response.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
};

export default http;
